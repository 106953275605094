<template>
  <div>
    <v-overlay :z-index="100" :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="grey darken-2"
          indeterminate
      ></v-progress-circular>
    </v-overlay>

    <div class="text-h5">Expenses</div>

    <!-- START BUTTONS -->
    <div class="text-right">
      <v-btn
          color="primary"
          class="mt-2"
          @click="addExpense()"
      >
        Add Expense
        <v-icon class="pl-3" dark>
          mdi-plus
        </v-icon>
      </v-btn>

      <v-btn
          :disabled="selectedIds.length < 1"
          class="ml-2 mt-2"
          color="primary"
          @click="carryExpenses()"
      >
        Carry to Next Month
        <v-icon class="pl-3" dark>
          mdi-content-copy
        </v-icon>
      </v-btn>

      <v-btn
          :disabled="selectedIds.length < 1"
          class="ml-2 mt-2"
          color="error"
          @click="batchDelete()"
      >
        Delete Selected
        <v-icon class="pl-3" dark>
          mdi-delete
        </v-icon>
      </v-btn>

      <v-btn
          class="ml-2 mt-2"
          depressed
      >
        <v-switch
          label="Show pay periods"
          v-model="groupExpenses"
        />
      </v-btn>

    </div>
    <!-- END BUTTONS -->

    <!-- EXPENSES TABLE START -->
    <v-data-table
        class="mt-5"
        :headers="tableHeaders"
        :items="filteredData"
        :footer-props="{'items-per-page-options': pagingOptions}"
        :items-per-page="25"

        sort-by="due-date"
        :sort-desc="true"
        show-select
        :group-by="groupingField"
        :group-desc="false"
        item-key="id"
        v-model="selectedExpenses"
    >
      <template v-slot:group.header="{items, isOpen, toggle}">
        <th colspan="3">
          <v-icon @click="toggle"
          >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
          </v-icon>
          {{ items[0].payroll }}
        </th>
        <th class="text-right">
          {{ formatCurrency(items.map((item) => item.raw_amount).reduce((previousValue, currentValue) => previousValue + currentValue)) }}
        </th>
        <th class="text-right">
          {{ formatCurrency(items.map((item) => item.raw_minimum).reduce((previousValue, currentValue) => previousValue + currentValue)) }}
        </th>
        <th class="text-right">
          {{ formatCurrency(items.map((item) => item.paid_raw).reduce((previousValue, currentValue) => previousValue + currentValue)) }}
        </th>
        <th class="text-right">
          {{ formatCurrency(items.map((item) => item.raw_left).reduce((previousValue, currentValue) => previousValue + currentValue)) }}
        </th>
        <th colspan="2">
          &nbsp;
        </th>
      </template>

      <template v-slot:top>
        <v-expansion-panels
            v-model="panels"
            multiple
            flat
            class="mb-3"
        >
          <!-- Summary -->
          <v-expansion-panel>
            <v-expansion-panel-header ripple class="d-flex d-md-none">Summary</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="0" md="1"></v-col>
                <v-col cols="5" md="2">
                  <v-card elevation="6" outlined>
                    <v-card-title><div class="text-center">Total</div></v-card-title>
                    <v-card-subtitle>{{ formattedTotalAmount }}</v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="7" md="2">
                  <v-card elevation="6" outlined>
                    <v-card-title><div class="text-center">Outstanding</div></v-card-title>
                    <v-card-subtitle>{{ formattedTotalLeft }}</v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="5" md="2">
                  <v-card elevation="6" outlined>
                    <v-card-title><div class="text-center">Minimum</div></v-card-title>
                    <v-card-subtitle>{{ formattedTotalMin }}</v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="7" md="2">
                  <v-card elevation="6" outlined>
                    <v-card-title><div class="text-center">Min Outstanding</div></v-card-title>
                    <v-card-subtitle>{{ formattedMinLeft }}</v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="12" md="2">
                  <v-card elevation="6" outlined color="error">
                    <v-card-title><div class="text-center">Late</div></v-card-title>
                    <v-card-subtitle>{{ formattedTotalLate }}</v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

        <v-spacer></v-spacer>

          <!-- Filtering -->
        <v-expansion-panel>
          <v-expansion-panel-header ripple class="d-flex d-md-none">Filtering</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" md="2">
                <v-select
                    :items="options"
                    item-value="id"
                    item-text="text"
                    v-model="filtering.periodFrom"
                    label="From"
                    placeholder="Select month"
                    clearable
                />
              </v-col>

              <v-col cols="12" md="2">
                <v-select
                    :items="toOptions"
                    item-value="id"
                    item-text="text"
                    v-model="filtering.periodTo"
                    label="To"
                    placeholder="Select month"
                    clearable
                />
              </v-col>

              <v-col cols="12" md="2">
                <v-select
                    :items="statusOpt"
                    item-value="id"
                    item-text="text"
                    v-model="filtering.status"
                    label="Payment Status" />
              </v-col>

              <v-col cols="12" md="2">
                <v-select
                    :items="accounts"
                    item-value="id"
                    item-text="title"
                    v-model="filtering.account"
                    label="Account"
                    placeholder="Select account"
                    clearable
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                    v-model="filtering.search"
                    label="Search by description"
                >

                </v-text-field>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        </v-expansion-panels>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="payExpense(item)"
        >
          mdi-cash
        </v-icon>

        <v-icon
            small
            class="mr-2"
            @click="editExpense(item)"
        >
          mdi-pencil
        </v-icon>

        <v-icon
            small
            class="mr-2"
            @click="copyExpense(item)"
        >
          mdi-content-copy
        </v-icon>

        <v-icon
            small
            @click="deleteExpense(item.id)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <!-- EXPENSES TABLE END -->

    <!-- PAYMENT CARD -->
    <v-dialog
        v-model="paymentMode"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Payment Details</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  md="3"
              >
                <v-text-field
                    label="Amount*"
                    required
                    v-model="paymentData.amount"
                    type="number"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="9"
              >
                <v-menu
                    v-model="paymentDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    max-width="290px"
                    min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        label="Date*"
                        prepend-icon="mdi-calendar"
                        readonly
                        :value="paymentData.payment_date"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="paymentData.payment_date"
                      no-title
                      @input="paymentDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                    :items="accounts"
                    item-value="id"
                    item-text="title"
                    v-model="paymentData.account_id"
                    label="Account"
                    placeholder="Select account"
                />
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="paymentMode = false"
          >
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="savePayment()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END PAYMENT CARD -->

    <!-- ADD/EDIT CARD -->
    <v-dialog
        v-model="editMode"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Expense Details</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    label="Description*"
                    required
                    v-model="editData.description"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="3"
              >
                <v-text-field
                    label="Amount*"
                    required
                    v-model="editData.amount"
                    prepend-inner-icon="mdi-cash"
                    type="number"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="3"
              >
                <v-text-field
                    label="Minimum*"
                    required
                    v-model="editData.minimum"
                    :placeholder="editData.amount"
                    prepend-inner-icon="mdi-cash"
                    type="number"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >
                <v-menu
                    v-model="editDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    max-width="290px"
                    min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        label="Date*"
                        prepend-icon="mdi-calendar"
                        readonly
                        :value="editData.due_date"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="editData.due_date"
                      no-title
                      @input="editDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >
                <v-select
                    v-model="editData.type"
                    :items="['Bill', 'Debt']"
                ></v-select>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                    :items="accounts"
                    item-value="id"
                    item-text="title"
                    v-model="editData.account"
                    label="Account"
                    placeholder="Select account"
                />
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                    v-model="editData.category"
                    :items="tagCategories"
                    item-value="id"
                    item-text="description"
                    dense
                    label="Category"
                ></v-autocomplete>
              </v-col>

            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="editMode = false"
          >
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveExpense()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--- END ADD/EDIT CARD -->
  </div>
</template>

<script>
import { formatAsCurrency } from "../../services/utils";

export default {
  name: "Expenses",

  data() {
    return {
      panels: [],
      pagingOptions: [10,25,50,100,-1],
      paymentDateMenu: false,
      editDateMenu: false,
      groupExpenses: false,
      filtering: {
        periodFrom: null,
        periodTo: null,
        search: '',
        status: 'unpaid',
        account: null,
        category: 1,
      },
      statusOpt: [
        { id: 'all', text: 'All' },
        { id: 'paid', text: 'Paid' },
        { id: 'unpaid', text: 'Unpaid' },
        { id: 'min-unpaid', text: 'Minimum Unpaid' },
      ],
      tableHeaders: [
        {
          value: 'due_date',
          text: 'Due Date',
        }, {
          value: 'description',
          text: 'Description',
        }, {
          value: 'amount',
          text: 'Amount',
          sortField: 'amount',
          align: 'end'
        }, {
          value: 'minimum',
          text: 'Minimum',
          sortField: 'minimum',
          align: 'end'
        }, {
          value: 'paid',
          text: 'Paid',
          sortField: 'paid',
          align: 'end'
        }, {
          value: 'left',
          text: 'Outstanding',
          sortField: 'left',
          align: 'end'
        }, {
          value: 'type',
          text: 'Type'
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      mobileWidth: 767,
      editMode: false,
      editData: {
        id: 0,
        description: '',
        amount: '',
        minimum: '',
        due_date: '',
        type: 'Bill',
        account: null,
        category: 1,
      },
      paymentMode: false,
      paymentData: {
        amount: '',
        payment_date: '',
        bill_id: 0,
        account_id: 0
      },
      selectedExpenses: [],
      isShown: false,
    }
  },

  mounted() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
      case 'sm':
        this.panels = [];
        break;
      case 'md':
      case 'lg':
      case 'xl':
        this.panels = [0,1];
        break;
    }


    this.$store.dispatch('fetchExpensesPeriods').then(() => {
      this.$store.dispatch('fetchAccounts').then(() => {
        this.$store.dispatch('fetchExpensesCategories').then(() => {
          this.$store.dispatch('fetchExpenses');
        });
      });
    });
  },

  computed: {
    accounts() {
      return this.$store.state.accounts.accounts;
    },

    categories() {
      return this.$store.state.expenses.categories;
    },

    tagCategories() {
      let tmp = [];
      this.categories.forEach((c) => {
        c.children.forEach((chld) => {
          tmp.push({
            id: chld.id,
            description: c.description + ' - ' + chld.description
          });
        })
      });

      return tmp;
    },

    groupingField() {
      return this.groupExpenses == true ? 'payroll' : [];
    },

    currentMonthOption() {
      let date = new Date();
      const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

      return this.options.findIndex((opt) => {
        return opt.month == months[date.getMonth()] && opt.year == date.getFullYear().toString();
      });
    },

    options() {
      return this.$store.state.expenses.periodsOptions;
    },

    toOptions() {
      return this.options.filter((o) => (
          this.filtering.periodFrom == null
          || o.id >= parseInt(this.filtering.periodFrom)
      ));
    },

    filteredData() {
      return this.expensesData.filter(
          (r) => (this.filtering.periodFrom == null || this.filtering.periodFrom <= r.due_timestamp)
      ).filter(
          (r) => (this.filtering.periodTo == null || this.filtering.periodTo >= r.due_timestamp)
      ).filter((r) => {
        return r.description.toLowerCase().includes(this.filtering.search.toLowerCase());
      }).filter((r) => {
        switch (this.filtering.status) {
          case 'paid':
            return !(r.raw_left > 0);

          case 'unpaid':
            return (r.raw_left > 0);

          case 'min-unpaid':
            return (r.raw_minimum - r.paid_raw) > 0;

          default:
            return true;
        }
      }).filter(
          (r) => (this.filtering.account == null || this.filtering.account == r.account_id)
      )
    },

    expensesData() {
      return this.$store.state.expenses.expenses.data;
    },

    loading() {
      return this.$store.state.expenses.loading || this.$store.state.payments.loading || this.$store.state.accounts.loading;
    },

    checkIsDesktop () {
      return window.matchMedia(`(min-width: ${this.mobileWidth}px)`).matches
    },

    totalAmount() {
      let total = 0;
      this.filteredData.forEach((r) => total += r.raw_amount);
      return Math.round(total * 100) / 100;
    },

    formattedTotalAmount() {
      return formatAsCurrency(this.totalAmount);
    },

    totalLeft() {
      let total = 0;
      this.filteredData.forEach((r) => total += r.raw_left);
      return Math.round(total * 100) / 100;
    },

    totalMinimum() {
      let total = 0;
      this.filteredData.forEach((r) => total += r.raw_minimum);
      return Math.round(total * 100) / 100;
    },

    totalMinLeft() {
      let total = 0;

      this.filteredData.forEach((r) => {
        let min_left = r.raw_minimum - r.paid_raw;
        total+= (min_left < 0) ? 0 : min_left;
      })

      return Math.round(total * 100) / 100;
    },

    currentDateInt() {
      let currentDate = new Date();
      currentDate = parseInt(
          currentDate.getFullYear().toString() + this.pad(currentDate.getMonth()+1).toString() + this.pad(currentDate.getDate()).toString()
      );

      return currentDate;
    },

    totalLate() {
      let total = 0;

      this.filteredData.forEach((r) => {
        if (r.due_int < this.currentDateInt) {
          let min_left = r.raw_minimum - r.paid_raw;
          total+= (min_left < 0) ? 0 : min_left;
        }
      })

      return Math.round(total * 100) / 100;
    },

    formattedTotalLeft() {
      return formatAsCurrency(this.totalLeft);
    },

    formattedTotalMin() {
      return formatAsCurrency(this.totalMinimum);
    },

    formattedMinLeft() {
      return formatAsCurrency(this.totalMinLeft);
    },

    formattedTotalLate() {
      return formatAsCurrency(this.totalLate);
    },

    payEnabled() {
      return (this.selectedExpenses.length > 0) && (this.editMode == false && this.paymentMode == false);
    },

    selectedIds() {
      return this.selectedExpenses.map((exp) => exp.id);
    }
  },

  methods: {
    addExpense() {
      this.editData = {
        id: 0,
        description: '',
        amount: '',
        minimum: '',
        due_date: '',
        type: 'Bill',
        account: this.accounts[0].id,
        category: 1,
      }

      this.editMode = true;
    },

    pad(n) {
      return n<10 ? '0'+n : n
    },

    editExpense(exp) {
      this.editData = {
        id: exp.id,
        description: exp.description,
        amount: exp.raw_amount,
        minimum: exp.raw_minimum,
        due_date: exp.due_date,
        type: exp.type,
        account: this.accounts.find((a) => a.id == exp.account_id).id,
        category: exp.category_id
      }

      this.editMode = true;
    },

    saveExpense() {
      if (this.editData.id < 1) {
        this.$store.dispatch('addExpense', this.editData);
      } else {
        this.$store.dispatch('updateExpense', this.editData);
      }
      this.editMode = false;
    },

    copyExpense(exp) {
      this.editData = {
        id: 0,
        description: exp.description,
        amount: exp.raw_amount,
        minimum: exp.raw_minimum,
        due_date: exp.due_date,
        type: exp.type,
        account: exp.account_id,
        category: exp.category_id
      }

      this.editMode = true;
    },

    deleteExpense(id) {
      this.$store.dispatch('deleteExpense', id);
    },

    payExpense(exp) {
      var d = new Date(),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      let now = [year, month, day].join('-');

      this.paymentData = {
        amount: exp.raw_left,
        payment_date: now,
        bill_id: exp.id,
        account_id: this.accounts.find((a) => a.id == exp.account_id).id
      }

      this.paymentMode = true;
    },

    savePayment() {
      this.$store.dispatch('addPayment', this.paymentData);
      this.paymentMode = false;
    },

    selectExpense(id) {
      if(this.selectedExpenses.indexOf(id) > -1) {
        this.selectedExpenses.splice(this.selectedExpenses.indexOf(id), 1);
      } else {
        this.selectedExpenses.push(id);
      }
    },

    expenseSelected(id) {
      return (this.selectedExpenses.indexOf(id) > -1) ? true : false;
    },

    carryExpenses() {
      this.$store.dispatch('carryExpenses', this.selectedIds);
      this.selectedExpenses = [];
    },

    batchDelete() {
      this.$store.dispatch('batchDeleteExpenses', this.selectedIds);
    },

    formatCurrency(amt) {
      return formatAsCurrency(Math.round(amt * 100) / 100);
    }
  },
}
</script>

<style scoped>

</style>